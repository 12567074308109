<template>
  <div class="index-wrap">
    <div class="head-box">
      <div>
        <img class="logo" src="../assets/qsxb/logo.png" alt="" srcset="" />
        <!-- <img class="logo2" src="../assets/qsxb/logo2.png" alt="" srcset="" /> -->
      </div>
      <div class="nav-box">
        <div class="nav-list" @click="topScroll(0)">
          首页
          <img
            src="../assets/qsxb/icon_selected.png"
            :style="{ opacity: check == 0 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(1)">
          我们的优势
          <img
            src="../assets/qsxb/icon_selected.png"
            :style="{ opacity: check == 1 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(2)">
          省钱攻略
          <img
            src="../assets/qsxb/icon_selected.png"
            :style="{ opacity: check == 2 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(3)">
          关于我们
          <img
            src="../assets/qsxb/icon_selected.png"
            :style="{ opacity: check == 3 ? 1 : 0 }"
          />
        </div>
      </div>
    </div>
    <div class="banner_view">
      <img class="banner" src="../assets/qsxb/banner2.png" alt="" srcset="" />
    </div>
    <div class="center">
      <img class="pic_6" src="../assets/qsxb/pic_6.png" alt="" srcset="" />
    </div>
    <div class="center app_down" ref="pic8">
      <img
        class="pic_8_2"
        src="../assets/qsxb/qsxb_pic_7.png"
        alt=""
        srcset=""
      />
      <img
        class="pic_down"
        @click="downOpenInstall"
        src="../assets/qsxb/downBtn.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="banner-wrap">
      <div class="banner-title center">
        <img
          class="pic_5"
          ref="pic5"
          src="../assets/qsxb/pic_5.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="banner-nav center">
        <div
          class="b-n-l"
          :class="{ active: bannerIndex === 0 }"
          @click="bannerIndex = 0"
        >
          淘宝/天猫省钱
        </div>
        <div
          class="b-n-l b-n-l-4"
          :class="{ active: bannerIndex === 1 }"
          @click="bannerIndex = 1"
        >
          京东省钱
        </div>
        <div
          class="b-n-l b-n-l-4"
          :class="{ active: bannerIndex === 2 }"
          @click="bannerIndex = 2"
        >
          外卖省钱
        </div>
      </div>
      <div class="banner-box">
        <div class="next-pre">
          <img
            class="icon_last"
            @click="handlePre"
            src="../assets/qsxb/icon_last.png"
            alt=""
            srcset=""
          />
          <img
            class="icon_next"
            @click="handleNext"
            src="../assets/qsxb/icon_next.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 0">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx0})` }"
          >
            <img src="../assets/tb_step_1.png" alt="" srcset="" />
            <img src="../assets/tb_step_2.png" alt="" srcset="" />
            <img src="../assets/tb_step_3.png" alt="" srcset="" />
            <img src="../assets/tb_step_4.png" alt="" srcset="" />
          </div>
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 1">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx1})` }"
          >
            <img src="../assets/jd_step_1.png" alt="" srcset="" />
            <img src="../assets/jd_step_2.png" alt="" srcset="" />
            <img src="../assets/jd_step_3.png" alt="" srcset="" />
            <img src="../assets/jd_step_4.png" alt="" srcset="" />
          </div>
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 2">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx2})` }"
          >
            <img src="../assets/wm_step_1.png" alt="" srcset="" />
            <img src="../assets/wm_step_2.png" alt="" srcset="" />
            <img src="../assets/wm_step_3.png" alt="" srcset="" />
            <img src="../assets/wm_step_4.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer" ref="footer">
      <img class="pic" src="../assets/qsxb/pic.png" alt="" srcset="" />
      <div class="des-box">
        <p class="footer-title">关于我们</p>
        <p>·商务合作/问题咨询</p>
        <p>·在线客服 如您遇到任何疑问，欢迎联系咨询</p>
        <p>·客服电话：400-999-0706</p>
        <p>·服务时间：9:00-18:00</p>
        <p class="icp">
          <a
            href="https://beian.miit.gov.cn/"
            style="color: #fff; margin-right: 5px"
            target="_blank"
            rel="noopener noreferrer"
            >蜀ICP备2023025328号-1</a
          >
          成都粟湫信息科技有限公司
        </p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      activeIndex: 0,
      bannerIndex: 0,
      leftPx0: "0",
      leftPx1: "0",
      leftPx2: "0",
      topPic8: 0,
      topPic5: 0,
      topFooter: 0,
      check: 0,
    };
  },
  methods: {
    handlePre() {
      this["leftPx" + this.bannerIndex] = "0";
    },
    handleNext() {
      this["leftPx" + this.bannerIndex] = "-385px";
    },
    topScroll(i) {
      let top = 0;
      this.check = i;
      switch (i) {
        case 0:
          top = 0;

          break;
        case 1:
          top = this.topPic8;
          break;
        case 2:
          top = this.topPic5;
          break;
        case 3:
          top = this.topFooter;
          break;
      }
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    scleTop() {
      console.info(this.$refs.pic8.offsetTop, "this.$refs.pic8.offsetTop");
      this.topPic8 = this.$refs.pic8.offsetTop - 87;
      this.topPic5 = this.$refs.pic5.offsetTop - 87;
      this.topFooter = this.$refs.footer.offsetTop - 87;
    },
    downOpenInstall() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      if (isAndroid) {
        var data = window.OpenInstall.parseUrlParams(); // /openinstall.js中提供的工具函数，解析url中的所有查询参数
        new window.OpenInstall(
          {
            /* appKey必选参数，openinstall平台为每个应用分配的ID*/
            appKey: "o4zx6v",
            /* 自定义遮罩的html*/
            // mask:function(){
            //  return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
            // },
            /* OpenInstall初始化完成的回调函数，可选*/
            onready: function () {
              /* 在app已安装的情况尝试拉起app*/
              this.schemeWakeup();

              /* 用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
              var m = this;
              m.wakeupOrInstall();
            },
          },
          data
        );
      } else {
        this.$dialog({
          type: "confirm-warnBargin",
        }).then(() => {});
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.scleTop();
    }, 200);
    window.onresize = () => {
      this.scleTop();
    };
  },
};
</script>

<style lang="scss">
.index-wrap {
  padding-top: 87px;
}
.down_box {
  position: relative;
  .fix_down {
    position: absolute;
    bottom: 105px;
    left: 936px;
    z-index: 999;
    text-align: left;
    .down_btn {
      display: inline-block;
      width: 120px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 12px;
      color: #fff;
      background-image: linear-gradient(to bottom, #ff811e, #ff5a01);
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      cursor: pointer;
    }
    .down_txt {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
}
.head-box {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 87px;
  background: #FFFFFF;
  padding: 0 400px;
  .logo {
    width: 180px;
    height: 41px;
    // margin-right: 10px;
  }
  .logo2 {
    width: 136px;
    height: 45px;
  }
  .nav-box {
    display: flex;
    align-items: center;
    .nav-list {
      font-size: 24px;
      color: #1A1A1A;
      margin-left: 91px;
      cursor: pointer;
      white-space: nowrap;
      img {
        margin: 5px auto 0;
        display: block;
        width: 38px;
        height: 6px;
      }
    }
  }
}
.center {
  text-align: center;
}
.banner_view{
  width: 100%;
  overflow: hidden;
}
.banner {
  width: 2997px;
}
.pic_7 {
  width: 990px;
}
.pic_6 {
  width: 996px;
  margin-top: 34px;
}
.pic_7 {
  margin-top: 179px;
}
.pic_8 {
  width: 1140px;
  margin-top: 36px;
}
.pic_8_2 {
  width: 990px;
}
.app_down {
  position: relative;
  width: 990px;
  margin: 156px auto 0;
}
.pic_down {
  width: 375px;
  position: absolute;
  top: 454px;
  left: 377px;
}
.banner-wrap {
  .banner-title {
    margin-top: 118px;
  }
  .banner-nav {
    width: 1140px;
    margin: 43px auto 0;
    display: flex;
    align-items: center;
    .b-n-l {
      flex: 1;
      text-align: center;
      color: #f64847;
      font-size: 24px;
      cursor: pointer;
    }
    .active {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 137px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        height: 3px;
        background: linear-gradient(270deg, #ff6f6f 0%, #f64847 100%);
        border-radius: 2px;
      }
    }
    .b-n-l-4 {
      &:after {
        width: 78px;
      }
    }
  }
  .banner-box {
    width: 1140px;
    margin: 28px auto 0;
    .banner-ls-box-h {
      width: 100%;
      overflow: hidden;
    }
    .banner-ls-box {
      display: flex;
      transition: 0.3s;
      img {
        width: 360px;
        height: 428px;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .next-pre {
      width: 100%;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        width: 74px;
        height: 74px;
        top: 155px;
        cursor: pointer;
      }
      .icon_last {
        left: -37px;
      }
      .icon_next {
        right: -37px;
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 111px;
  background: #FE4B4B;
  height: 462px;
  padding: 0 435px 0 443px;
  width: 100%;
  .pic {
    width: 376px;
    height: 429px;
  }
  .des-box {
    width: 595px;
    .footer-title {
      font-size: 47px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    p {
      font-size: 28px;
      color: #ffffff;
      line-height: 55px;
      white-space: nowrap;
    }
    .icp {
      font-size: 23px;
    }
  }
}
</style>
